<!-- 精品课堂-详情 -->
<template>
    <div class="wrapper classroom-wrapper video-wrapper flex flex-column">
        <Header :navList="navList" navCurrentId="4" :logo="logoUrl"></Header>
        <div class="flex-item">
            <div class="container mobile-bread flex x-left">
                <div class="color999">您所在的位置：</div>
                <a-breadcrumb class="flex x-left">
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{ path: '/' }">首页</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item>
                        <router-link class="color999" :to="{ path: '/course/index?type=2' }">精品课堂</router-link>
                    </a-breadcrumb-item>
                     <a-breadcrumb-item>
                        <router-link class="color999" :to="{ path: '/course/classroom-details?id='+course_id }">{{courseDetail.title}}</router-link>
                    </a-breadcrumb-item>
                    <a-breadcrumb-item style="max-width:760px;" class="row-1">{{videoInfo.title}}</a-breadcrumb-item>
                </a-breadcrumb>
            </div>
            <div class="classroom-detail container">
                 <!-- 视频信息 -->
            <div class="video-play container flex">
                <div class="video-outer-box">
                    <course-play v-if="videoInfo.sign" :params="videoInfo" :bgImg="videoInfo.default_pic_url | urlFilter(750)">
                    </course-play>
                    <!-- 权限提示 -->
                    <div v-else class="no-vid-box" @click="handleVideoAuthEvent">
                        <div class="no-vid-img">
                            <img :src="videoInfo.default_pic_url | urlFilter(750)" alt="">
                        </div>
                        <div class="video-auth-box flex-column x-center y-center">
                            <i class="med med-bofang"></i>
                            <div v-if="videoAuth.msg" class="fs16 colorfff mt10">{{ videoAuth.msg }}</div>
                            <a-button v-if="videoAuth.btnText" class="mt15 fs16" style="border-color:#fff;color:#fff;background:transparent;">{{videoAuth.btnText}}</a-button>
                        </div>
                    </div>
                </div>
                <div class="video-detail flex-column x-between">
                    <!-- 个人信息 -->
                    <div class="video-content-name pl20 pr20 flex y-center">
                        <div class="flex y-center" style="cursor: pointer;" @click="expertJumpDetail(videoInfo.user_id)">
                            <img class="img" :src="videoInfo.avatar | urlFilter(250)" alt="">
                            <div class="flex-column ml15" style="max-width:180px;">
                                <p class="t-l row-1">
                                    <span class="strong fs16 color333">{{ videoInfo.real_name }}</span>
                                    <span class="ml10 color999 fs12">{{ videoInfo.department }}</span>
                                </p>
                                <p class="t-l mt5 color666 fs12 row-1">{{ videoInfo.org_cnname }}</p>
                            </div>
                        </div>
                        <div class="attention-btn flex x-center y-center cursor" @click="getFollowExperts(videoInfo.user_id,true)" v-if="videoInfo.fans_status == 0">
                            <i class="med med-jiahao"></i>
                            <span class="fs12 strong colorfff ml5">关注</span>
                        </div>
                        <div class="isattention-btn flex x-center y-center cursor" @click="getFollowExperts(videoInfo.user_id)" v-if="videoInfo.fans_status == 1 || videoInfo.fans_status == 2">
                            <span class="fs12 strong color999 ml5">已关注</span>
                        </div>
                    </div>
                    <!-- 粉丝数等 -->
                    <div style="height:80px;" class="video-content-name flex x-around pl10 pr10 y-center">
                        <div class="flex-column">
                            <span class="strong color333 fs18">{{ videoInfo.video_num || 0 }}</span>
                            <span class="fs12 color333">视频</span>
                        </div>
                        <div class="line"></div>
                        <div class="flex-column">
                            <span class="strong color333 fs18">{{ videoInfo.abstract_num || 0 }}</span>
                            <span class="fs12 color333">论文</span>
                        </div>
                        <div class="line"></div>
                        <div class="flex-column">
                            <span class="strong color333 fs18">{{ videoInfo.fans_count || 0 }}</span>
                            <span class="fs12 color333">粉丝</span>
                        </div>
                    </div>
                    <!-- 个人介绍 -->
                    <div v-if="videoInfo.intro" class="video-content-name content-desc t-l">
                        <div class="pt10 pb20 pl20 pr20" ref="resumeContent" v-html="videoInfo.intro"></div>
                    </div>
                    <div v-else class="video-content-name content-desc pt20 pb20 pl20 pr20 t-l color999">暂无介绍
                    </div>
                    <!-- 关键词 -->
                    <div class="content-subject flex x-left y-center">
                        <template v-if="videoInfo.keyword">
                                <span class="content-subject-item colorfff fs12 t-l" :class="{ 'ml10': index != 0 }" v-for="(item, index) in strToArr(videoInfo.keyword)" :key="index">{{ item
                                    }}</span>
                        </template>
                    </div>
                    <!-- 浏览量等 -->
                    <div class="page-view flex y-center">
                        <div>
                            <i class="med med-yanjing"></i>
                            <span class="fs12 color999 ml5">{{ videoInfo.hits }}人</span>
                        </div>
                        <div class="color999 fs12">
                            <span class="cursor" @click="getVideoLikeCollect">
                                <i :class="['med', videoInfo.is_collect == 1 ? 'med-shoucang1' : 'med-shoucang']"></i>
                                <span class="ml5">{{ videoInfo.is_collect == 1 ? '已收藏' : '收藏' }}</span>
                            </span>
                            <!-- <span class="ml15 cursor" @click="getVideoLikeCollect('1')">
                                <i :class="['med', videoInfo.zan == 1 ? 'med-dianzan' : 'med-dianzan_huaban']"></i>
                                <span class="ml5">{{ videoInfo.zan == 1 ? '已赞' : '点赞' }}</span>
                            </span> -->
                            <!-- <span class="ml15 cursor">
                                <img src="~@/assets/images/transpond.png" alt="">
                                <span class="ml5">转发</span>
                            </span> -->
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <!-- table切换 -->
            <div class="classroom-table container t-l">
                <a-tabs v-model="activeKey" :animated="false" @change="handleTabChangeEvent">
                    <a-tab-pane key="1" tab="课程介绍" class="mt10">
                        <a-spin :spinning="loading" tip="Loading...">
                            <div class="introduce" v-html="courseDetail.intro" style="min-height:200px;">
                            </div>
                        </a-spin>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="课程目录">
                        <a-spin :spinning="loading" tip="Loading...">
                            <div class="catalogue" style="min-height:200px;">
                                <div v-for="(item,index) in dirList" :key="index" class="catalogue-item flex x-between y-center">
                                    <div class="catalogue-left flex-item">
                                        <div class="row-2 fs16 strong">{{item.title}}</div>
                                        <div class="mt5">
                                            <span class="fs14 strong">{{item.real_name}}</span>
                                            <span class="ml10">{{item.org_cnname}}</span>
                                        </div>
                                    </div>

                                    <div class="btn-play-loading" v-if="item.id == video_id">正在观看...</div>
                                    <!-- 已交费或者免费 -->
                                    <router-link v-else-if="courseDetail.is_sale == 1 || courseDetail.power_type == 1" :to="`/course/classroom-play?id=${item.id}&course_id=${courseDetail.id}`" class="catalogue-right">
                                        立即观看
                                    </router-link>
                                </div>
                                <div class="t-c mt30" v-if="dirCount > 0">
                                    <a-pagination :total="dirCount" :current="dirParams.page" :page-size="dirParams.limit" show-quick-jumper @change="handlePageChangeEvent" :hideOnSinglePage="true" />
                                </div>
                            </div>
                        </a-spin>
                    </a-tab-pane>
                    <a-tab-pane key="3" tab="留言评论">
                        <a-spin :spinning="loading" tip="Loading...">
                            <div class="container" style="min-height:200px; max-width:100%;">
                                <comment-list :list="commentList" :commentCount="commentCount" :pageSize="commentParams.limit" @pageEvent="commentPageChangeEvent($event)" @handleTextChange="handleTextChange($event)"></comment-list>
                            </div>
                        </a-spin>
                    </a-tab-pane>
                </a-tabs>
            </div>
        </div>
        <Footer></Footer>
        <buy-modal ref="buyModal"></buy-modal>
    </div>
</template>

<script>
import coursePlay from '@/components/course-player/course-play.vue';
import Header from '@/components/layout/layout-header';
import Footer from '@/components/layout/layout-footer';
import commentList from '@/components/comment-list.vue';
import { videoLogo, mediaNavList } from '@/config/const';
import { setWeixinShare } from "@/utils/wechatShare";
import buyModal from '@/components/buy-modal.vue';
import { mapState } from 'vuex';
export default {
    components: {
        Header,
        Footer,
        coursePlay,
        commentList,
        buyModal
    },
    name: "classroomDetails",
    data() {
        return {
            navList: mediaNavList, //顶部菜单
            logoUrl: videoLogo,
            activeKey: '2',
            loading: false,

            // 视频权限
            videoAuth: {
                code: '', // code类型
                msg: '', // 权限提示
            },
            videoInfo: {}, //视频详情

            course_id:this.$route.query.course_id,   // 课程id
            video_id:this.$route.query.id,           // 视频id

            commentList: [], //视频评论
            commentCount: 1, //评论总数
            commentParams: {
                page: 1,
                limit: 5,
            },

            dirList : [],   // 目录列表
            dirCount: 0,    // 目录数量
            dirParams: {
                page: 1,
                limit:5,
            },

            courseDetail: {}
        }
    },
    watch:{
        '$route.query':{
            handler(query){
                this.course_id = query.course_id,   // 课程id
                this.video_id = query.id,           // 视频id
                this.getVideoInfo();
                this.getPremiumVideoInfo();
                this.getCourseListDir();
            },
            immediate:true,
        }
    },
    computed: {
        ...mapState(['userInfo'])
    },
    methods: {
        // 选项卡切换
        handleTabChangeEvent(key) {
            // 留言评论
            if (key == 3) {
                this.getCommentList();
            } else if(key == 2){
                this.getCourseListDir();
            }
        },

        // 关键词分割
        strToArr(str) {
            return str.split('|')
        },

        //视频权限跳转
        handleVideoAuthEvent() {
            // 需要收费，跳转到收费页面
            if (this.videoAuth.code == 201) {
                // 1视频购买订单 2系列课购买订单
                this.$refs.buyModal.show(this.courseDetail,2);
            }else if(this.videoAuth.code == 401){
                window.location.replace("https://www.sciconf.cn/unified/login?return_url=" + encodeURIComponent(window.location.href))
            }
        },

        //关注
        getFollowExperts(userid, is_focus) {
            this.request.post('FollowExperts', { experts_uid: userid }).then(res => {
                if (res.data.code == 200) {
                    this.videoInfo.fans_status = this.videoInfo.fans_status == 0 ? 1 : 0
                }
                //关注医生添加积分,只有关注的时候才加积分
                if (is_focus) {
                    this.request.post('AddPoint', { task_id: 3, type_id: userid }, { hideModal: true }).then(res => {}).catch(err => {})
                }

            })
        },
        // 收藏/点赞
        getVideoLikeCollect() {
            this.request.get('addOnlineCollect', { type: 1, type_id: this.video_id }).then(res => {
                this.videoInfo.is_collect = !this.videoInfo.is_collect;
            })
        },

        // 获取课程目录
        getCourseListDir(){
            this.loading = true
            this.dirParams.course_id = this.course_id;
            this.request.get('getCourseListDir', this.dirParams).then(res => {
                this.dirCount = parseInt(res.data.count);
                this.dirList = res.data.list;
                this.loading = false;
            })
        },

        // 切换视频目录
        handlePageChangeEvent(e){
            this.dirParams.page = e
            this.getCourseListDir()
        },

        // 视频评论
        getCommentList() {
            this.loading = true
            this.commentParams.type = 7;
            this.commentParams.type_id = this.course_id;
            this.request.get('getOnlineCommentList', this.commentParams).then(res => {
                this.commentCount = parseInt(res.data.count)
                this.commentList = res.data.list
                this.loading = false
            })
        },
        // 视频评论切换页码
        commentPageChangeEvent(e) {
            this.commentParams.page = e
            this.getCommentList()
        },
        // 发表评论
        handleTextChange(e) {
            let data = {
                type:7,
                content:e,
                type_id:this.course_id
            }
            this.request.get('addOnlineComment', data).then(res => {
                this.commentParams.page = 1;
                this.getCommentList()
            })
        },
        // 获取视频详情
        getVideoInfo() {
            this.request.get('getPremiumVideoInfo', { video_id: this.video_id }, { hideModal: true }).then(res => {
                let { code, msg, data } = res;
                this.videoInfo = data;

                // 判断是否登陆
                if(!this.userInfo.user_id){
                    this.videoAuth = {
                        code:401,
                        msg:'您暂未登陆，请登陆后观看',
                        btnText:'立即登陆'
                    }
                    return;
                }

                // 判断是否为非200业务
                if (code != 200) {
                    this.videoAuth = {
                        code,
                        msg,
                        btnText: code == 201 ? '立即购买' : ''
                    }
                }
            })
        },
        // 获取课程详情
        getPremiumVideoInfo() {
            this.request.get('getCourseInfo', { course_id: this.course_id }).then((res) => {
                this.courseDetail = res.data;
                 // 设置分享
                let share_title = res.data.share_title || res.data.title;
                let share_desc = res.data.share_desc || res.data.intro.substr(0,30);
                setWeixinShare(share_title,share_desc,res.data.default_pic_url)
            })
        },
    },
    created() {
       
    }
}
</script>

<style scoped lang="less">
@import url('~@/assets/css/course-play.less');
.classroom-table {
    padding: 20px;
    box-sizing: border-box;
    /deep/.ant-tabs-bar {
        border-bottom: 0;
        border-top: 1px solid #d8d8d8;
    }
    /deep/.ant-tabs-nav {
        float: left;
    }
    /deep/.ant-tabs-ink-bar {
        bottom: 0;
        top: 0;
        background: #1e51c9;
    }
    /deep/.ant-tabs-tab {
        font-size: 20px;
        color: #333333;
        padding: 10px 0 0;
    }
    /deep/.ant-tabs-tab-active {
        color: #1e51c9;
    }
    .catalogue {
        .catalogue-item {
            width: 100%;
            padding: 15px;
            box-sizing: border-box;
            box-shadow: 0px 0px 4px 0px rgba(30, 81, 201, 0.15);
            border: 1px solid #F9F9F9;
            border-radius: 5px;
            margin-bottom: 15px;
            .catalogue-right {
                background: linear-gradient(to right, #5f96e6, #1e51c9);
                border-radius: 20px;
                padding: 8px 20px;
                text-align: center;
                font-size: 14px;
                cursor: pointer;
                color: #FFFFFF;
                white-space: nowrap;
            }
        }
    }
}
.btn-play-loading{
    color:@theme;
    padding:0 15px;
}

@media screen and (max-width: 768px) {
    .mobile-bread {
        display: none;
    }
    .classroom-table {
        width: 100%;
        padding: 10px !important;
        /deep/.ant-tabs-tab {
            font-size: 15px !important;
            margin: 0;
            &.ant-tabs-tab-active {
                font-weight: bold;
            }
        }
        /deep/.ant-tabs-nav {
            width: 100%;
            >div {
                display: flex;
                justify-content: space-around;
            }
        }
        /deep/.ant-tabs-ink-bar {
            display: none !important;
        }
         .catalogue-item{
            padding:10px!important;
        }
        .catalogue-right {
            line-height: 1;
            padding:7px 15px!important;
        }
    }
}
</style>
